<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Exportar Inquérito de Satisfação - Instalações</h1>

    <DataTable
      ref="dt"
      :value="answeres"
      :loading="loading"
      :paginator="false"
      :autoLayout="true"
      :rows="answeres.length"
      class="p-datatable-sm p-datatable-responsive"
    >
      <template #header>
        <div style="text-align: left" class="p-fluid p-formgrid p-grid">
          <div class="field p-ml-1">
            <label for="start">Data de Início</label>
            <v-date-picker
              v-model="start"
              :masks="{
                input: 'YYYY-MM-DD'
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  name="start"
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>

          <div class="field p-ml-2">
            <label for="end">Data de Fim</label>
            <v-date-picker
              v-model="end"
              :min-date="start"
              :masks="{
                input: 'YYYY-MM-DD'
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  name="end"
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <div>
            <Button
              v-if="end >= start"
              class="p-ml-2 p-mt-3"
              icon="pi pi-search"
              label="Pesquisar"
              @click="getStatisfactionsForms"
            />
          </div>
          <div>
            <Button
              v-if="answeres.length > 0"
              class="p-ml-3 p-mt-3"
              icon="pi pi-external-link"
              label="Export"
              @click="exportCSV($event)"
            />
          </div>
        </div>
        <small v-if="start > end" class="p-error" role="alert">
          Datas Incorretas
        </small>
      </template>
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column field="id" header="Id">
        <template #body="slotProps">
          {{ slotProps.data.id }}
        </template>
      </Column>
      <Column field="name" header="Nome">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
      </Column>
      <Column field="description" header="Descrição">
        <template #body="slotProps">
          {{ slotProps.data.description }}
        </template>
      </Column>
      <Column field="seller" header="Vendedor">
        <template #body="slotProps">
          {{ slotProps.data.seller }}
        </template>
      </Column>
      <Column field="manager" header="Gestor">
        <template #body="slotProps">
          {{ slotProps.data.manager }}
        </template>
      </Column>
      <Column field="responsible_technical" header="Responsável">
        <template #body="slotProps">
          {{ slotProps.data.responsible_technical }}
        </template>
      </Column>
      <Column field="installation_date" header="Data Instalação">
        <template #body="slotProps">
          {{ slotProps.data.installation_date }}
        </template>
      </Column>
      <Column
        v-for="question in questions"
        :key="question.id"
        :field="question.id.toString()"
        :header="question.question"
      >
        <template #body="slotProps">
          {{ slotProps.data[question.id] }}
        </template>
      </Column>
      <Column field="created" header="Data Questionário">
        <template #body="slotProps">
          {{ slotProps.data.created }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import satisfactionFormService from "../services/satisfactionForm.service";
import { getOnlyDate } from "../helpers/helpers";

export default {
  name: "InstallationsFormExport",
  data() {
    return {
      loading: false,
      questions: [],
      answeres: [],
      footer1: { created: "Média" },
      footer2: { created: "Percentagem" },
      footerTotal: 0,
      start: new Date(),
      end: new Date()
    };
  },
  methods: {
    async getStatisfactionsForms() {
      this.loading = true;
      this.footer1 = { created: "Média" };
      this.footer2 = { created: "Percentagem" };
      this.footerTotal = 0;
      await this.getFormsExport();
      this.getFooter();
      let numberForms = this.answeres.length;
      this.answeres.push(this.footer1);
      this.answeres.push(this.footer2);
      let total = this.footerTotal/(Object.keys(this.footer2).length -2 )
      this.answeres.push({created: `Percentagem Total: ${total.toFixed(3)} %`});
      this.answeres.push({created: `Número de Inquéritos: ${numberForms}`})
    },
    async getFormsExport() {
      this.loading = true;
      return satisfactionFormService
        .getInstallationsToExport(
          getOnlyDate(this.start),
          getOnlyDate(this.end)
        )
        .then(response => {
          this.loading = false;
          this.questions = response.questions;
          this.answeres = response.awsweres;
        });
    },
    getFooter() {
      /* eslint-disable no-unused-vars */
      for (const [key, value] of Object.entries(this.questions)) {
        let questionsToRemove = 0;
        this.footer1[value.id] = this.answeres.reduce(function(sum, current) {
          if (typeof current[value.id] === "number" && current[value.id] == 0) {
            questionsToRemove++;
          }
          if (typeof current[value.id] === "number" && current[value.id] > 0) {
            return sum + current[value.id];
          }
          return sum + 0;
        }, 0);
        if (this.footer1[value.id] == 0) {
          this.footer1[value.id] = null;
          this.footer2[value.id] = null;
        } else {
          this.footer1[value.id] = (
            this.footer1[value.id] / (this.answeres.length - questionsToRemove)
          ).toFixed(3);
          this.footer2[value.id] = `${(
            (this.footer1[value.id] * 100) /
            4
          ).toFixed(3)} %`;
          this.footerTotal += parseFloat((this.footer1[value.id] * 100) / 4);
        }
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    }
  }
};
</script>
